import { IconProps } from '../../../interfaces/icons/iconProps';
import React from 'react';

export const ColdSettingsIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_806_1512)">
        <path
          d="M7.2763 5.50572C8.62944 5.93867 10.0973 5.33065 10.748 4.06769C11.2724 3.04988 12.7276 3.04988 13.252 4.06769C13.9027 5.33065 15.3706 5.93867 16.7237 5.50572C17.8142 5.15681 18.8432 6.18581 18.4943 7.2763C18.0613 8.62944 18.6694 10.0973 19.9323 10.748C20.9501 11.2724 20.9501 12.7276 19.9323 13.252C18.6694 13.9027 18.0613 15.3706 18.4943 16.7237C18.8432 17.8142 17.8142 18.8432 16.7237 18.4943C15.3706 18.0613 13.9027 18.6694 13.252 19.9323C12.7276 20.9501 11.2724 20.9501 10.748 19.9323C10.0973 18.6694 8.62944 18.0613 7.2763 18.4943C6.18581 18.8432 5.15681 17.8142 5.50572 16.7237C5.93867 15.3706 5.33065 13.9027 4.06769 13.252C3.04988 12.7276 3.04988 11.2724 4.06769 10.748C5.33065 10.0973 5.93867 8.62944 5.50572 7.2763C5.15681 6.18581 6.18581 5.15681 7.2763 5.50572Z"
          stroke={props.color || 'white'}
          strokeWidth="1.5"
        />
        <rect x="8.75" y="8.75" width="6.5" height="6.5" rx="3.25" stroke={props.color || 'white'} strokeWidth="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_806_1512">
          <rect width="24" height="24" fill={props.color || 'white'} />
        </clipPath>
      </defs>
    </svg>
  );
};
