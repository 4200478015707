export const getCheckboxAnimation = () => {
  return {
    v: '4.8.0',
    meta: { g: 'LottieFiles AE 3.4.5', a: '', k: '', d: '', tc: '' },
    fr: 29.9700012207031,
    ip: 0,
    op: 30.0000012219251,
    w: 32,
    h: 32,
    nm: 'Check Animation',
    ddd: 0,
    assets: [],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: 'CheckMark',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [16, 16, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [150, 150, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-2.9, -0.15],
                      [-0.3, 2.15],
                      [3.15, -2.1],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1.5, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Shape 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.208], y: [1] },
                  o: { x: [0.469], y: [0] },
                  t: 15,
                  s: [0],
                },
                { t: 25.0000010182709, s: [100] },
              ],
              ix: 1,
            },
            e: { a: 0, k: 0, ix: 2 },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 0,
        op: 150.000006109625,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: 'Outline',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [16, 15.375, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.41, 0.41, 0.667], y: [0.601, 0.601, 1] },
                o: { x: [0.496, 0.496, 0.333], y: [0, 0, 0] },
                t: 0,
                s: [100, 100, 100],
              },
              {
                i: { x: [0.357, 0.357, 0.667], y: [-2.492, -2.492, 1] },
                o: { x: [0.688, 0.688, 0.333], y: [-3.508, -3.508, 0] },
                t: 8,
                s: [50, 50, 100],
              },
              {
                i: { x: [0.5, 0.5, 0.667], y: [1, 1, 1] },
                o: { x: [0.67, 0.67, 0.333], y: [0.272, 0.272, 0] },
                t: 11,
                s: [50, 50, 100],
              },
              {
                i: { x: [0.286, 0.286, 0.833], y: [1, 1, 1] },
                o: { x: [0.501, 0.501, 0.333], y: [0, 0, 0] },
                t: 20,
                s: [120, 120, 100],
              },
              { t: 25.0000010182709, s: [100, 100, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [24, 24], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.833], y: [0.833] },
                      o: { x: [0.167], y: [0.167] },
                      t: 8,
                      s: [0.156862745098, 0.172549019608, 0.243137254902, 1],
                    },
                    {
                      t: 20.0000008146167,
                      s: [0.282352954149, 0.360784322023, 0.917647063732, 1],
                    },
                  ],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 1.5, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.833], y: [0.833] },
                      o: { x: [0.167], y: [0.167] },
                      t: 8,
                      s: [0.282352954149, 0.360784322023, 0.917647063732, 1],
                    },
                    {
                      t: 20.0000008146167,
                      s: [0.282352954149, 0.360784322023, 0.917647063732, 1],
                    },
                  ],
                  ix: 4,
                },
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.833], y: [0.833] },
                      o: { x: [0.167], y: [0.167] },
                      t: 0,
                      s: [0],
                    },
                    {
                      i: { x: [0.833], y: [0.833] },
                      o: { x: [0.167], y: [0.167] },
                      t: 8,
                      s: [100],
                    },
                    { t: 20.0000008146167, s: [100] },
                  ],
                  ix: 5,
                },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 150.000006109625,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: 'Green 01',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [0],
              },
              { t: 15.0000006109625, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.219], y: [1] },
                  o: { x: [0.611], y: [0] },
                  t: 0,
                  s: [17.581],
                },
                {
                  i: { x: [0.469], y: [1] },
                  o: { x: [0.6], y: [0] },
                  t: 15,
                  s: [29.447],
                },
                { t: 20.0000008146167, s: [17.581] },
              ],
              ix: 3,
            },
            y: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.06], y: [0] },
                  t: 0,
                  s: [16],
                },
                {
                  i: {
                    x: [0.98],
                    y: [1],
                  },
                  o: { x: [0.333], y: [0] },
                  t: 15,
                  s: [9.393],
                },
                { t: 20.0000008146167, s: [16] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [-0.172, -2.752, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [3.162, 3.162], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0.4, 1, 0.662745098039, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-1.752, -2.752], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 150.000006109625,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: 'Green 02',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [0],
              },
              { t: 15.0000006109625, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: { a: 0, k: 17.759, ix: 3 },
            y: {
              a: 1,
              k: [
                {
                  i: { x: [0.319], y: [1] },
                  o: { x: [0.42], y: [0] },
                  t: 0,
                  s: [16],
                },
                {
                  i: {
                    x: [0.465],
                    y: [1],
                  },
                  o: { x: [0.604], y: [0] },
                  t: 15,
                  s: [29.592],
                },
                { t: 20.0000008146167, s: [16] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [-0.172, -2.752, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [3.162, 3.162], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0.4, 1, 0.662745098039, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-1.752, -2.752], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 150.000006109625,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 4,
        nm: 'Green 03',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [0],
              },
              { t: 15.0000006109625, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.329], y: [1] },
                  o: { x: [0.489], y: [0] },
                  t: 0,
                  s: [17.581],
                },
                {
                  i: { x: [0.467], y: [1] },
                  o: { x: [0.601], y: [0] },
                  t: 15,
                  s: [6.239],
                },
                { t: 20.0000008146167, s: [17.581] },
              ],
              ix: 3,
            },
            y: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [17.25],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 15,
                  s: [23.679],
                },
                { t: 20.0000008146167, s: [17.25] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [-0.172, -2.752, 0], ix: 1 },
          s: { a: 0, k: [150, 150, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [3.162, 3.162], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0.4, 1, 0.662745098039, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-1.752, -2.752], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 150.000006109625,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 6,
        ty: 4,
        nm: 'Blue 01',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [0],
              },
              { t: 15.0000006109625, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.404], y: [1] },
                  o: { x: [0.692], y: [0] },
                  t: 0,
                  s: [17.581],
                },
                {
                  i: { x: [0.466], y: [1] },
                  o: { x: [0.6], y: [0] },
                  t: 15,
                  s: [10.138],
                },
                { t: 20.0000008146167, s: [17.581] },
              ],
              ix: 3,
            },
            y: {
              a: 1,
              k: [
                {
                  i: { x: [0.404], y: [1] },
                  o: { x: [0.692], y: [0] },
                  t: 0,
                  s: [16],
                },
                {
                  i: { x: [0.466], y: [1] },
                  o: { x: [0.6], y: [0] },
                  t: 15,
                  s: [3.557],
                },
                { t: 20.0000008146167, s: [16] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [-0.172, -2.752, 0], ix: 1 },
          s: { a: 0, k: [150, 150, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [3.162, 3.162], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0.4, 0.78431372549, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-1.752, -2.752], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 150.000006109625,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 7,
        ty: 4,
        nm: 'Blue 02',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [0],
              },
              { t: 15.0000006109625, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.396], y: [1] },
                  o: { x: [0.635], y: [0] },
                  t: 0,
                  s: [17.581],
                },
                {
                  i: { x: [0.471], y: [1] },
                  o: { x: [0.6], y: [0] },
                  t: 15,
                  s: [9.793],
                },
                { t: 20.0000008146167, s: [17.581] },
              ],
              ix: 3,
            },
            y: {
              a: 1,
              k: [
                {
                  i: { x: [0.396], y: [1] },
                  o: { x: [0.648], y: [0] },
                  t: 0,
                  s: [16],
                },
                {
                  i: { x: [0.471], y: [1] },
                  o: { x: [0.6], y: [0] },
                  t: 15,
                  s: [28.851],
                },
                { t: 20.0000008146167, s: [16] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [-0.172, -2.752, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [3.162, 3.162], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0.4, 0.78431372549, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-1.752, -2.752], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 150.000006109625,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 8,
        ty: 4,
        nm: 'Blue 03',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [0],
              },
              { t: 15.0000006109625, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.792], y: [0] },
                  t: 0,
                  s: [17.581],
                },
                {
                  i: { x: [0.466], y: [1] },
                  o: { x: [0.601], y: [0] },
                  t: 15,
                  s: [30.381],
                },
                { t: 20.0000008146167, s: [17.581] },
              ],
              ix: 3,
            },
            y: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.792], y: [0] },
                  t: 0,
                  s: [16],
                },
                {
                  i: {
                    x: [0.466],
                    y: [1],
                  },
                  o: { x: [0.601], y: [0] },
                  t: 15,
                  s: [18.86],
                },
                { t: 20.0000008146167, s: [16] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [-0.172, -2.752, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [3.162, 3.162], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [0.4, 0.78431372549, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-1.752, -2.752], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 150.000006109625,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 9,
        ty: 4,
        nm: 'Yellow 01',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [0],
              },
              { t: 15.0000006109625, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.792], y: [0] },
                  t: 0,
                  s: [17.581],
                },
                {
                  i: { x: [0.466], y: [1] },
                  o: { x: [0.601], y: [0] },
                  t: 15,
                  s: [21.992],
                },
                { t: 20.0000008146167, s: [17.581] },
              ],
              ix: 3,
            },
            y: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.792], y: [0] },
                  t: 0,
                  s: [16],
                },
                {
                  i: {
                    x: [0.466],
                    y: [1],
                  },
                  o: { x: [0.601], y: [0] },
                  t: 15,
                  s: [4.068],
                },
                { t: 20.0000008146167, s: [16] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [-0.172, -2.752, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [3.162, 3.162], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.945098039216, 0.4, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-1.752, -2.752], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 150.000006109625,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 10,
        ty: 4,
        nm: 'Yellow 02',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [0],
              },
              { t: 15.0000006109625, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.396], y: [1] },
                  o: { x: [0.635], y: [0] },
                  t: 0,
                  s: [17.581],
                },
                {
                  i: { x: [0.471], y: [1] },
                  o: { x: [0.6], y: [0] },
                  t: 15,
                  s: [27.982],
                },
                { t: 20.0000008146167, s: [17.581] },
              ],
              ix: 3,
            },
            y: {
              a: 1,
              k: [
                {
                  i: { x: [0.396], y: [1] },
                  o: { x: [0.648], y: [0] },
                  t: 0,
                  s: [16],
                },
                {
                  i: { x: [0.471], y: [1] },
                  o: { x: [0.6], y: [0] },
                  t: 15,
                  s: [28.104],
                },
                { t: 20.0000008146167, s: [16] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [-0.172, -2.752, 0], ix: 1 },
          s: { a: 0, k: [150, 150, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [3.162, 3.162], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.945098039216, 0.4, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-1.752, -2.752], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 150.000006109625,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 11,
        ty: 4,
        nm: 'Yellow 03',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [0],
              },
              { t: 15.0000006109625, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.404], y: [1] },
                  o: { x: [0.692], y: [0] },
                  t: 0,
                  s: [17.581],
                },
                {
                  i: { x: [0.466], y: [1] },
                  o: { x: [0.6], y: [0] },
                  t: 15,
                  s: [5.436],
                },
                { t: 20.0000008146167, s: [17.581] },
              ],
              ix: 3,
            },
            y: {
              a: 1,
              k: [
                {
                  i: { x: [0.404], y: [1] },
                  o: { x: [0.692], y: [0] },
                  t: 0,
                  s: [16],
                },
                {
                  i: { x: [0.466], y: [1] },
                  o: { x: [0.6], y: [0] },
                  t: 15,
                  s: [9.509],
                },
                { t: 20.0000008146167, s: [16] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [-0.172, -2.752, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [3.162, 3.162], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.945098039216, 0.4, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-1.752, -2.752], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 150.000006109625,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 12,
        ty: 4,
        nm: 'Red 01',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [0],
              },
              { t: 15.0000006109625, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.792], y: [0] },
                  t: 0,
                  s: [17.581],
                },
                {
                  i: { x: [0.466], y: [1] },
                  o: { x: [0.601], y: [0] },
                  t: 15,
                  s: [30.156],
                },
                { t: 20.0000008146167, s: [17.581] },
              ],
              ix: 3,
            },
            y: {
              a: 1,
              k: [
                {
                  i: { x: [0.4], y: [1] },
                  o: { x: [0.792], y: [0] },
                  t: 0,
                  s: [16],
                },
                {
                  i: {
                    x: [0.466],
                    y: [1],
                  },
                  o: { x: [0.601], y: [0] },
                  t: 15,
                  s: [2.964],
                },
                { t: 20.0000008146167, s: [16] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [-0.172, -2.752, 0], ix: 1 },
          s: { a: 0, k: [75, 75, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [3.162, 3.162], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.996078431373, 0.403921568627, 0.509803921569, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-1.752, -2.752], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 150.000006109625,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 13,
        ty: 4,
        nm: 'Red 02',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [0],
              },
              { t: 15.0000006109625, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.396], y: [1] },
                  o: { x: [0.635], y: [0] },
                  t: 0,
                  s: [17.581],
                },
                {
                  i: { x: [0.471], y: [1] },
                  o: { x: [0.6], y: [0] },
                  t: 15,
                  s: [30.22],
                },
                { t: 20.0000008146167, s: [17.581] },
              ],
              ix: 3,
            },
            y: {
              a: 1,
              k: [
                {
                  i: { x: [0.396], y: [1] },
                  o: { x: [0.648], y: [0] },
                  t: 0,
                  s: [16],
                },
                {
                  i: { x: [0.471], y: [1] },
                  o: { x: [0.6], y: [0] },
                  t: 15,
                  s: [23.056],
                },
                { t: 20.0000008146167, s: [16] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [-0.172, -2.752, 0], ix: 1 },
          s: { a: 0, k: [75, 75, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [3.162, 3.162], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.996078431373, 0.403921568627, 0.509803921569, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-1.752, -2.752], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 150.000006109625,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 14,
        ty: 4,
        nm: 'Red 03',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [0],
              },
              { t: 15.0000006109625, s: [100] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            s: true,
            x: {
              a: 1,
              k: [
                {
                  i: { x: [0.404], y: [1] },
                  o: { x: [0.692], y: [0] },
                  t: 0,
                  s: [17.581],
                },
                {
                  i: { x: [0.466], y: [1] },
                  o: { x: [0.6], y: [0] },
                  t: 15,
                  s: [6.197],
                },
                { t: 20.0000008146167, s: [17.581] },
              ],
              ix: 3,
            },
            y: {
              a: 1,
              k: [
                {
                  i: { x: [0.404], y: [1] },
                  o: { x: [0.692], y: [0] },
                  t: 0,
                  s: [16],
                },
                {
                  i: { x: [0.466], y: [1] },
                  o: { x: [0.6], y: [0] },
                  t: 15,
                  s: [15.605],
                },
                { t: 20.0000008146167, s: [16] },
              ],
              ix: 4,
            },
          },
          a: { a: 0, k: [-0.172, -2.752, 0], ix: 1 },
          s: { a: 0, k: [75, 75, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [3.162, 3.162], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.996078431373, 0.403921568627, 0.509803921569, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-1.752, -2.752], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 150.000006109625,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  };
};
