import { IconProps } from '@coldpbc/interfaces';

export const ColdInfoIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00002 12.3333C10.3933 12.3333 12.3334 10.3932 12.3334 8C12.3334 5.60677 10.3933 3.66667 8.00002 3.66667C5.60679 3.66667 3.66669 5.60677 3.66669 8C3.66669 10.3932 5.60679 12.3333 8.00002 12.3333ZM8.00002 13.3333C10.9455 13.3333 13.3334 10.9455 13.3334 8C13.3334 5.05449 10.9455 2.66667 8.00002 2.66667C5.0545 2.66667 2.66669 5.05449 2.66669 8C2.66669 10.9455 5.0545 13.3333 8.00002 13.3333Z"
        fill={props.color || 'white'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 7.33333C8.36817 7.33333 8.66665 7.63181 8.66665 7.99999V9.99999C8.66665 10.3682 8.36817 10.6667 7.99998 10.6667C7.63179 10.6667 7.33331 10.3682 7.33331 9.99999V7.99999C7.33331 7.63181 7.63179 7.33333 7.99998 7.33333Z"
        fill={props.color || 'white'}
      />
      <path
        d="M8.66665 5.99999C8.66665 6.36818 8.36817 6.66666 7.99998 6.66666C7.63179 6.66666 7.33331 6.36818 7.33331 5.99999C7.33331 5.63181 7.63179 5.33333 7.99998 5.33333C8.36817 5.33333 8.66665 5.63181 8.66665 5.99999Z"
        fill={props.color || 'white'}
      />
    </svg>
  );
};
