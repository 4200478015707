import { IconProps } from '@coldpbc/interfaces';

export const ColdSupportNotFilledInIcon = (props: IconProps) => {
  if (props.inverted) {
    return <div className={'w-full h-full bg-gray-70 rounded-full'}></div>;
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4875 15.0229C13.8375 16.6728 11.1625 16.6728 9.5126 15.0229C8.91933 14.4297 8.53939 13.7039 8.37277 12.9408L12.5 12.9408L16.6273 12.9408C16.4607 13.7039 16.0807 14.4297 15.4875 15.0229ZM18.4677 12.9408C18.282 14.1714 17.7154 15.3557 16.7678 16.3033C14.4108 18.6603 10.5893 18.6603 8.23227 16.3033C7.28471 15.3557 6.71808 14.1714 6.53239 12.9408L5.67161 12.9408C5.17161 12.9408 4.76628 12.5355 4.76628 12.0355C4.76628 11.5355 5.17161 11.1302 5.67161 11.1302H6.53239C6.71808 9.89954 7.28471 8.71529 8.23227 7.76773C10.5893 5.41071 14.4108 5.41071 16.7678 7.76773C17.7154 8.71529 18.282 9.89954 18.4677 11.1302L19.3284 11.1302C19.8284 11.1302 20.2338 11.5355 20.2338 12.0355C20.2338 12.5355 19.8284 12.9408 19.3284 12.9408L18.4677 12.9408ZM8.37277 11.1302L12.5 11.1302L16.6273 11.1302C16.4607 10.3671 16.0807 9.64132 15.4875 9.04806C13.8375 7.39815 11.1625 7.39815 9.5126 9.04806C8.91933 9.64132 8.53939 10.3671 8.37277 11.1302Z"
          fill={props.color || 'white'}
        />
      </svg>
    );
  }
};
