import * as React from 'react';
import { HexColors } from '../../../themes/cold_theme';

export const ColdClimateWordmark = (
  props: { color: string; stroke?: string } & React.SVGProps<SVGSVGElement>,
) => {
  const color: string = props.color || HexColors.white;
  const strokeColor: string = props.stroke || color;

  return (
    <svg
      {...props}
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      width="651.724"
      viewBox="0 0 651.724 80.229"
    >
      <g data-name="Layer 1">
        <path
          className={props.className}
          d="M403.593 8.529h9.936v63.172h-9.936zM457.651 57.816 440.645 8.529h-15.049v63.172h9.343v-51.5l18.149 51.5h8.168l17.451-51.404v51.404h9.935V8.529h-14.85l-16.141 49.287zM520.344 8.528 496.27 71.7h9.644l6.837-18.297h25.679l7.022 18.297h10.293L531.202 8.528h-10.858Zm-4.598 36.72 9.707-25.747 9.879 25.747h-19.586ZM547.191 16.982h20.427v54.719h9.837V16.982h20.824V8.529h-51.088v8.453zM651.724 16.982V8.529h-44.848v63.172h44.2v-9.018h-34.299v-19.12h25.301v-8.354h-25.301V16.982h34.947z"
          opacity={0.6}
          stroke={`${strokeColor}`}
          fill={`${color}`}
        />
        <path
          d="M32.47 63.568c-12.931 0-23.452-10.52-23.452-23.452s10.52-23.452 23.452-23.452c10.885 0 20.061 7.455 22.692 17.528h9.227C61.595 19.108 48.35 7.646 32.47 7.646 14.566 7.646 0 22.212 0 40.117s14.566 32.466 32.47 32.466c15.882 0 29.127-11.463 31.92-26.546h-9.226c-2.63 10.074-11.807 17.532-22.693 17.532Z"
          className={props.className}
          stroke={`${strokeColor}`}
          fill={`${color}`}
        />
        <path
          d="M312.913 63.568c-12.931 0-23.452-10.52-23.452-23.452s10.52-23.452 23.452-23.452c10.885 0 20.061 7.455 22.692 17.528h9.227c-2.794-15.084-16.039-26.546-31.919-26.546-17.904 0-32.47 14.566-32.47 32.47s14.566 32.466 32.47 32.466c15.882 0 29.127-11.463 31.92-26.546h-9.226c-2.63 10.074-11.807 17.532-22.693 17.532Z"
          className={props.className}
          stroke={`${strokeColor}`}
          fill={`${color}`}
          opacity={0.6}
        />
        <path
          className={props.className}
          d="M154.7 8.529h-9.901v63.172h41.979v-9.018H154.7V8.529z"
          fill={`${color}`}
          stroke={`${strokeColor}`}
        />
        <path
          className={props.className}
          d="M362.473 8.529h-9.901v63.172h41.98v-9.018h-32.079V8.529z"
          fill={`${color}`}
          stroke={`${strokeColor}`}
          opacity={0.6}
        />
        <path
          d="M216.758 8.529h-21.383v63.172h21.383c18.973 0 31.232-12.294 31.232-31.321 0-19.647-11.967-31.851-31.232-31.851Zm0 54.154h-11.42V17.55h11.42c13.633 0 21.772 8.534 21.772 22.827 0 13.759-8.343 22.306-21.772 22.306ZM124.914 15.857 135.727 0h-9.919l-7.655 11.225h-.002l-5.134 7.529h.002L87.006 56.901c-4.373-4.262-7.095-10.21-7.095-16.784 0-12.931 10.52-23.452 23.452-23.452.382 0 .762.011 1.14.029l5.675-8.322a32.432 32.432 0 0 0-6.815-.725c-17.904 0-32.47 14.566-32.47 32.47 0 9.668 4.254 18.357 10.982 24.31L71.099 80.229h9.917l27.69-40.606h.002l11.07-16.234c4.339 4.258 7.037 10.183 7.037 16.728 0 12.931-10.52 23.452-23.452 23.452-.352 0-.701-.011-1.049-.027l-5.683 8.334c2.173.46 4.424.706 6.732.706 17.904 0 32.47-14.564 32.47-32.466 0-9.64-4.227-18.307-10.92-24.259Z"
          fill={`${color}`}
          stroke={`${strokeColor}`}
          className={props.className}
        />
      </g>
    </svg>
  );
};
