import { IconProps } from '@coldpbc/interfaces';

export const ColdExpiringIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.86437 0.785435C4.14839 1.12391 4.10424 1.62854 3.76576 1.91255L1.31431 3.96957C0.975834 4.25358 0.471206 4.20943 0.187192 3.87096C-0.0968228 3.53248 -0.0526736 3.02785 0.285802 2.74384L2.73726 0.686825C3.07573 0.402811 3.58036 0.44696 3.86437 0.785435ZM12.1393 0.785436C12.4233 0.44696 12.9279 0.402811 13.2664 0.686825L15.7179 2.74384C16.0564 3.02785 16.1005 3.53248 15.8165 3.87096C15.5325 4.20943 15.0278 4.25358 14.6894 3.96957L12.2379 1.91255C11.8994 1.62854 11.8553 1.12391 12.1393 0.785436ZM8.00039 3.6998C4.90745 3.6998 2.40013 6.20712 2.40013 9.30006C2.40013 12.393 4.90745 14.9003 8.00039 14.9003C11.0933 14.9003 13.6006 12.393 13.6006 9.30006C13.6006 6.20712 11.0933 3.6998 8.00039 3.6998ZM0.800055 9.30006C0.800055 5.32342 4.02375 2.09973 8.00039 2.09973C11.977 2.09973 15.2007 5.32342 15.2007 9.30006C15.2007 13.2767 11.977 16.5004 8.00039 16.5004C4.02375 16.5004 0.800055 13.2767 0.800055 9.30006ZM8.00039 4.49984C8.44223 4.49984 8.80042 4.85802 8.80042 5.29987V8.50002H12.0006C12.4424 8.50002 12.8006 8.85821 12.8006 9.30006C12.8006 9.7419 12.4424 10.1001 12.0006 10.1001H8.00039C7.55854 10.1001 7.20035 9.7419 7.20035 9.30006V5.29987C7.20035 4.85802 7.55854 4.49984 8.00039 4.49984Z"
        fill={props.color || 'white'}
      />
    </svg>
  );
};
