import { IconProps } from '@coldpbc/interfaces';

export const ColdCalendarEventIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        d="M4.38086 8H20.3809M4.38086 8V16.8002C4.38086 17.9203 4.38086 18.4801 4.59885 18.9079C4.79059 19.2842 5.09633 19.5905 5.47266 19.7822C5.90006 20 6.45985 20 7.57777 20H17.1839C18.3019 20 18.8608 20 19.2883 19.7822C19.6646 19.5905 19.9713 19.2842 20.1631 18.9079C20.3809 18.4805 20.3809 17.9215 20.3809 16.8036V8M4.38086 8V7.2002C4.38086 6.08009 4.38086 5.51962 4.59885 5.0918C4.79059 4.71547 5.09633 4.40973 5.47266 4.21799C5.90048 4 6.46095 4 7.58105 4H8.38086M20.3809 8V7.19691C20.3809 6.07899 20.3809 5.5192 20.1631 5.0918C19.9713 4.71547 19.6646 4.40973 19.2883 4.21799C18.8604 4 18.3012 4 17.1811 4H16.3809M8.38086 4H16.3809M8.38086 4V2M16.3809 4V2M12.1309 16C12.2689 16 12.3809 15.8881 12.3809 15.75V12.25C12.3809 12.1119 12.2689 12 12.1309 12H8.63086C8.49279 12 8.38086 12.1119 8.38086 12.25V15.75C8.38086 15.8881 8.49279 16 8.63086 16H12.1309Z"
        stroke={props.color || '#E0E1EA'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
