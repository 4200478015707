export enum GlobalSizes {
  xxxSmall = '3xs',
  xxSmall = '2xs',
  xSmall = 'xs',
  small = 'sm',
  medium = 'md',
  large = 'lg',
  xLarge = 'xl',
  xxLarge = '2xl',
  xxxLarge = '3xl',
}
