import { IconProps } from '@coldpbc/interfaces';

export const ColdSparkleIcon = (props: IconProps) => {
  if (props.filled) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4.5C12.6277 4.5 13.189 4.89079 13.4068 5.47945L13.8784 6.75396C14.4557 8.31416 15.6858 9.54428 17.246 10.1216L18.5206 10.5932C19.1092 10.811 19.5 11.3723 19.5 12C19.5 12.6277 19.1092 13.189 18.5206 13.4068L17.246 13.8784C15.6858 14.4557 14.4557 15.6858 13.8784 17.246L13.4068 18.5206C13.189 19.1092 12.6277 19.5 12 19.5C11.3723 19.5 10.811 19.1092 10.5932 18.5206L10.1216 17.246C9.54428 15.6858 8.31416 14.4557 6.75396 13.8784L5.47945 13.4068C4.89079 13.189 4.5 12.6277 4.5 12C4.5 11.3723 4.89079 10.811 5.47945 10.5932L6.75396 10.1216C8.31416 9.54428 9.54428 8.31416 10.1216 6.75396L10.5932 5.47945C10.811 4.89079 11.3723 4.5 12 4.5Z"
          fill={props.color || 'white'}
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 7.5C21.0461 7.5 21.9816 8.15132 22.3446 9.13241L23.1307 11.2566C24.0929 13.8569 26.1431 15.9071 28.7434 16.8693L30.8676 17.6554C31.8487 18.0184 32.5 18.9539 32.5 20C32.5 21.0461 31.8487 21.9816 30.8676 22.3446L28.7434 23.1307C26.1431 24.0929 24.0929 26.1431 23.1307 28.7434L22.3446 30.8676C21.9816 31.8487 21.0461 32.5 20 32.5C18.9539 32.5 18.0184 31.8487 17.6554 30.8676L16.8693 28.7434C15.9071 26.1431 13.8569 24.0929 11.2566 23.1307L9.13241 22.3446C8.15132 21.9816 7.5 21.0461 7.5 20C7.5 18.9539 8.15132 18.0184 9.13241 17.6554L11.2566 16.8693C13.8569 15.9071 15.9071 13.8569 16.8693 11.2566L17.6554 9.13241C18.0184 8.15132 18.9539 7.5 20 7.5ZM20 10L19.214 12.1242C19.1283 12.3559 19.0357 12.5841 18.9365 12.8086C18.8388 13.0298 18.7347 13.2474 18.6244 13.4612C17.4819 15.6763 15.6763 17.4819 13.4612 18.6244C13.2474 18.7347 13.0298 18.8388 12.8086 18.9365C12.5841 19.0357 12.3559 19.1283 12.1242 19.214L10 20L12.1242 20.786C12.3559 20.8717 12.5841 20.9643 12.8086 21.0635C13.0298 21.1612 13.2474 21.2653 13.4613 21.3756C15.6763 22.5181 17.4819 24.3237 18.6244 26.5388C18.7347 26.7526 18.8388 26.9702 18.9365 27.1914C19.0357 27.4159 19.1283 27.6441 19.214 27.8758L20 30L20.786 27.8758C20.8717 27.6441 20.9643 27.4159 21.0635 27.1914C21.1612 26.9702 21.2653 26.7526 21.3756 26.5388C22.5181 24.3237 24.3237 22.5181 26.5388 21.3756C26.7526 21.2653 26.9702 21.1612 27.1914 21.0635C27.4159 20.9643 27.6441 20.8717 27.8758 20.786L30 20L27.8758 19.214C27.6441 19.1283 27.4159 19.0357 27.1914 18.9365C26.9702 18.8388 26.7526 18.7347 26.5388 18.6244C24.3237 17.4819 22.5181 15.6763 21.3756 13.4613C21.2653 13.2474 21.1612 13.0298 21.0635 12.8086C20.9643 12.5841 20.8717 12.3559 20.786 12.1242L20 10Z"
          fill={props.color || 'white'}
        />
      </svg>
    );
  }
};
