import { IconProps } from '@coldpbc/interfaces';

export const ColdSuppliersNavIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M18.1265 18.5847L17.5042 15.7836C17.4021 15.3263 16.9963 15.0013 16.5278 15.0013H12.7822"
        stroke={props.color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0888 20.7506L12.5051 13.7787C12.4015 13.3235 11.9966 13.0004 11.5297 13.0004H7.14891C6.67054 13 6.25883 13.3384 6.1665 13.8078L5.33716 18.0405"
        stroke={props.color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 21.0038C16.9727 21.0038 21.0038 16.9727 21.0038 12C21.0038 7.02739 16.9727 2.99628 12.0001 2.99628C7.02745 2.99628 2.99634 7.02739 2.99634 12C2.99634 16.9727 7.02745 21.0038 12.0001 21.0038Z"
        stroke={props.color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 21.0038C16.9727 21.0038 21.0038 16.9727 21.0038 12C21.0038 7.02739 16.9727 2.99628 12.0001 2.99628C7.02745 2.99628 2.99634 7.02739 2.99634 12C2.99634 16.9727 7.02745 21.0038 12.0001 21.0038Z"
        stroke={props.color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0005 6.99792C13.0005 7.82669 12.3287 8.49854 11.4999 8.49854C10.6711 8.49854 9.99927 9.1704 9.99927 9.99918"
        stroke={props.color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.002 9.99915C17.002 10.5517 16.5541 10.9996 16.0016 10.9996C15.4491 10.9996 15.0012 11.4475 15.0012 12"
        stroke={props.color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
