import { ColdLogosProps } from '@coldpbc/components';
import { HexColors } from '@coldpbc/themes';

export const ColdSymbol = (props: ColdLogosProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="198"
      height="120"
      viewBox="0 0 198 120"
      fill="none"
      {...props}
      color={props.color || HexColors.primary.DEFAULT}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M141.313 13.7405L162.293 -16.5H143.048L128.196 4.90696C128.196 4.90629 128.194 4.90563 128.193 4.90496L118.232 19.2628C118.233 19.2628 118.235 19.2634 118.236 19.2641L67.7651 92.0119C59.2814 83.8831 53.9986 72.5419 53.9986 60.0043C53.9986 35.3439 74.4103 15.2806 99.5 15.2806C100.242 15.2806 100.979 15.3012 101.712 15.3357L112.722 -0.53503C108.456 -1.43418 104.035 -1.91792 99.5 -1.91792C64.7621 -1.91792 36.5 25.86 36.5 60.0043C36.5 78.4418 44.7534 95.0126 57.8076 106.364L36.9003 136.5H56.1421L109.867 59.062H109.87L131.349 28.1029C139.767 36.2237 145.002 47.5225 145.002 60.0043C145.002 84.6648 124.59 104.728 99.5 104.728C98.8174 104.728 98.1403 104.706 97.4652 104.677L86.4384 120.571C90.6545 121.449 95.0219 121.918 99.5 121.918C134.239 121.918 162.5 94.1433 162.5 60.0043C162.5 41.6206 154.299 25.0916 141.313 13.7405Z"
        fill="currentColor"
      />
    </svg>
  );
};
