import { IconProps } from '@coldpbc/interfaces';

export const ColdSwitchIcon = (props: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2237 0.186112C11.4718 -0.0620373 11.8742 -0.0620375 12.1223 0.186112L15.0654 3.12921C15.1846 3.24838 15.2515 3.41 15.2515 3.57853C15.2515 3.74705 15.1846 3.90868 15.0654 4.02784L12.1223 6.97094C11.8742 7.21909 11.4718 7.21909 11.2237 6.97094C10.9755 6.7228 10.9755 6.32047 11.2237 6.07232L13.0821 4.21395H0.635427C0.28449 4.21395 0 3.92946 0 3.57853C0 3.22759 0.28449 2.9431 0.635427 2.9431H13.0821L11.2237 1.08474C10.9755 0.836592 10.9755 0.434262 11.2237 0.186112Z"
        fill={props.color || 'white'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.04271 8.81477C3.79456 8.56662 3.39223 8.56662 3.14408 8.81477L0.200978 11.7579C0.0818119 11.877 0.0148659 12.0387 0.0148659 12.2072C0.0148659 12.3757 0.0818119 12.5373 0.200978 12.6565L3.14408 15.5996C3.39223 15.8478 3.79456 15.8478 4.04271 15.5996C4.29086 15.3515 4.29086 14.9491 4.04271 14.701L2.18435 12.8426H14.6162C14.9671 12.8426 15.2516 12.5581 15.2516 12.2072C15.2516 11.8563 14.9671 11.5718 14.6162 11.5718H2.18435L4.04271 9.7134C4.29086 9.46525 4.29086 9.06292 4.04271 8.81477Z"
        fill={props.color || 'white'}
      />
    </svg>
  );
};
