import { IconProps } from '@coldpbc/interfaces';

export const ColdAiIcon = (props: IconProps) => {
  if (props.inverted) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
        <circle cx="6" cy="6.8335" r="6" fill={props.color || 'white'} />
        <path
          d="M6 2.8335L6.31441 3.68317C6.80058 4.99703 7.83647 6.03292 9.15032 6.51909L10 6.8335L9.15032 7.14791C7.83647 7.63407 6.80058 8.66997 6.31441 9.98382L6 10.8335L5.68559 9.98382C5.19942 8.66997 4.16353 7.63407 2.84968 7.14791L2 6.8335L2.84968 6.51909C4.16353 6.03292 5.19942 4.99703 5.68559 3.68317L6 2.8335Z"
          fill="black"
        />
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props} color={props.color || 'white'}>
        <path
          d="M5.43 11.52C5.22 10.23 4.29 8.625 2.55 7.305C1.68 6.645 0.825 6.225 0 6.06V5.43C1.665 5.055 3.315 3.885 4.395 2.355C4.935 1.59 5.28 0.809999 5.43 0H6.06C6.315 1.53 7.515 3.195 9.09 4.32C9.87 4.875 10.68 5.25 11.52 5.43V6.06C9.825 6.405 7.89 7.875 6.945 9.39C6.465 10.155 6.165 10.86 6.06 11.52H5.43ZM5.64 11.16H5.85C6 9.6 6.465 7.905 6.99 7.02C7.995 6.45 9.81 5.97 11.13 5.88V5.67C9.81 5.565 7.92 5.055 6.99 4.5C6.465 3.6 6 1.95 5.85 0.389999H5.64C5.52 1.8 5.07 3.495 4.5 4.5C3.54 5.055 1.74 5.565 0.36 5.67V5.88C1.725 5.985 3.48 6.45 4.5 7.02C5.055 7.995 5.52 9.72 5.64 11.16Z"
          fill="currentColor"
        />
        <path
          d="M5.43 11.52C5.22 10.23 4.29 8.625 2.55 7.305C1.68 6.645 0.825 6.225 0 6.06V5.43C1.665 5.055 3.315 3.885 4.395 2.355C4.935 1.59 5.28 0.809999 5.43 0H6.06C6.315 1.53 7.515 3.195 9.09 4.32C9.87 4.875 10.68 5.25 11.52 5.43V6.06C9.825 6.405 7.89 7.875 6.945 9.39C6.465 10.155 6.165 10.86 6.06 11.52H5.43ZM5.64 11.16H5.85C6 9.6 6.465 7.905 6.99 7.02C7.995 6.45 9.81 5.97 11.13 5.88V5.67C9.81 5.565 7.92 5.055 6.99 4.5C6.465 3.6 6 1.95 5.85 0.389999H5.64C5.52 1.8 5.07 3.495 4.5 4.5C3.54 5.055 1.74 5.565 0.36 5.67V5.88C1.725 5.985 3.48 6.45 4.5 7.02C5.055 7.995 5.52 9.72 5.64 11.16Z"
          fill="currentColor"
        />
        <path
          d="M5.43 11.52C5.22 10.23 4.29 8.625 2.55 7.305C1.68 6.645 0.825 6.225 0 6.06V5.43C1.665 5.055 3.315 3.885 4.395 2.355C4.935 1.59 5.28 0.809999 5.43 0H6.06C6.315 1.53 7.515 3.195 9.09 4.32C9.87 4.875 10.68 5.25 11.52 5.43V6.06C9.825 6.405 7.89 7.875 6.945 9.39C6.465 10.155 6.165 10.86 6.06 11.52H5.43ZM5.64 11.16H5.85C6 9.6 6.465 7.905 6.99 7.02C7.995 6.45 9.81 5.97 11.13 5.88V5.67C9.81 5.565 7.92 5.055 6.99 4.5C6.465 3.6 6 1.95 5.85 0.389999H5.64C5.52 1.8 5.07 3.495 4.5 4.5C3.54 5.055 1.74 5.565 0.36 5.67V5.88C1.725 5.985 3.48 6.45 4.5 7.02C5.055 7.995 5.52 9.72 5.64 11.16Z"
          fill="currentColor"
        />
        <path
          d="M5.43 11.52C5.22 10.23 4.29 8.625 2.55 7.305C1.68 6.645 0.825 6.225 0 6.06V5.43C1.665 5.055 3.315 3.885 4.395 2.355C4.935 1.59 5.28 0.809999 5.43 0H6.06C6.315 1.53 7.515 3.195 9.09 4.32C9.87 4.875 10.68 5.25 11.52 5.43V6.06C9.825 6.405 7.89 7.875 6.945 9.39C6.465 10.155 6.165 10.86 6.06 11.52H5.43ZM5.64 11.16H5.85C6 9.6 6.465 7.905 6.99 7.02C7.995 6.45 9.81 5.97 11.13 5.88V5.67C9.81 5.565 7.92 5.055 6.99 4.5C6.465 3.6 6 1.95 5.85 0.389999H5.64C5.52 1.8 5.07 3.495 4.5 4.5C3.54 5.055 1.74 5.565 0.36 5.67V5.88C1.725 5.985 3.48 6.45 4.5 7.02C5.055 7.995 5.52 9.72 5.64 11.16Z"
          fill="currentColor"
        />
        <path
          d="M5.43 11.52C5.22 10.23 4.29 8.625 2.55 7.305C1.68 6.645 0.825 6.225 0 6.06V5.43C1.665 5.055 3.315 3.885 4.395 2.355C4.935 1.59 5.28 0.809999 5.43 0H6.06C6.315 1.53 7.515 3.195 9.09 4.32C9.87 4.875 10.68 5.25 11.52 5.43V6.06C9.825 6.405 7.89 7.875 6.945 9.39C6.465 10.155 6.165 10.86 6.06 11.52H5.43ZM5.64 11.16H5.85C6 9.6 6.465 7.905 6.99 7.02C7.995 6.45 9.81 5.97 11.13 5.88V5.67C9.81 5.565 7.92 5.055 6.99 4.5C6.465 3.6 6 1.95 5.85 0.389999H5.64C5.52 1.8 5.07 3.495 4.5 4.5C3.54 5.055 1.74 5.565 0.36 5.67V5.88C1.725 5.985 3.48 6.45 4.5 7.02C5.055 7.995 5.52 9.72 5.64 11.16Z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
