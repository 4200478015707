import { IconProps } from '@coldpbc/interfaces';

export const ColdComplianceIcon = (props: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99791 4.99707H8.99875C9.55126 4.99707 9.99916 5.44498 9.99916 5.99749V7.99832H5.9975V5.99749C5.9975 5.44498 6.4454 4.99707 6.99791 4.99707Z"
        stroke={props.color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.99829 2.99628V4.99711" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12 15.0012V8.99877C12 8.44625 11.5521 7.99835 10.9996 7.99835H4.99706C4.44455 7.99835 3.99664 8.44625 3.99664 8.99877V21.0037"
        stroke={props.color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M18.4957 18.0025V21.0037" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M18.5037 10.9996H18.5016C17.1209 10.9996 16.0016 12.1189 16.0016 13.4996V15.5025C16.0016 16.8832 17.1209 18.0025 18.5016 18.0025H18.5037C19.8844 18.0025 21.0037 16.8832 21.0037 15.5025V13.4996C21.0037 12.1189 19.8844 10.9996 18.5037 10.9996Z"
        stroke={props.color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.99829 18.0025H14.0008" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.08374 21.0037V18.0025" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.0004 21.0037V18.0025" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M17.0021 6.99794C18.1071 6.99794 19.0029 6.10213 19.0029 4.99711C19.0029 3.89208 18.1071 2.99628 17.0021 2.99628C15.8971 2.99628 15.0013 3.89208 15.0013 4.99711C15.0013 6.10213 15.8971 6.99794 17.0021 6.99794Z"
        stroke={props.color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.49768 14.0008H9.49893" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.49768 10.9996H9.49893" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.0042 21.0037H1.99585" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
