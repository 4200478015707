export const ScopeColors: {
  [key: number]: 'lightblue' | 'purple' | 'green' | 'teal';
} = {
  1: 'lightblue',
  2: 'purple',
  3: 'teal',
};

export enum EmissionsScopesCardVariants {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export const EmissionsScope3Categories = [
  'Purchased Goods and Services',
  'Capital Goods',
  'Fuel- and Energy-Related Activities Not Included in Scope 1 or 2',
  'Upstream Transportation and Distribution',
  'Waste Generated in Operations',
  'Business Travel',
  'Employee Commuting',
  'Upstream Leased Assets',
  'Downstream Transportation and Distribution',
  'Processing of Sold Products',
  'Use of Sold Products',
  'End-of-Life Treatment of Sold Products',
  'Downstream Leased Assets',
  'Franchises',
  'Investments',
];
