import { IconProps } from '../../../interfaces/icons/iconProps';

export const ColdActionsCompletedIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        d="M12.6797 2.99609C17.6523 2.99609 21.6834 7.02721 21.6834 11.9998C21.6834 16.9724 17.6523 21.0035 12.6797 21.0035"
        stroke={props.color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.59976 20.4526C8.61283 20.0953 7.69598 19.5681 6.89062 18.895" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.89062 5.10501C7.69548 4.43115 8.61247 3.90392 9.59976 3.54736" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.81641 10.4372C3.99718 9.40253 4.3585 8.40764 4.88385 7.49805" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.81641 13.5615C3.99707 14.5966 4.35839 15.5919 4.88385 16.5018" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.0809 15.3312L14.7516 11.9998H10.75L12.4207 8.66846" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.0809 15.3312L14.7516 11.9998H10.75L12.4207 8.66846" stroke={props.color || 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.7498 21.0036C17.7225 21.0036 21.7536 16.9725 21.7536 11.9998C21.7536 7.02721 17.7225 2.99609 12.7498 2.99609C7.77721 2.99609 3.74609 7.02721 3.74609 11.9998C3.74609 16.9725 7.77721 21.0036 12.7498 21.0036Z"
        stroke={props.color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
