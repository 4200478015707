// TODO: Delete this after all colors have been switched over to using new Turtle theme
export enum DefaultHexColors {
  starkWhite = '#FFFFFF',
  skyBlue = '#2892D7',
  superiorBlue = '#1D70A2',
  midnightBlue = '#0A1C2B',
  lichenGreen = '#84A98C',
  pineGreen = '#2E674A',
  astralYellow = '#F7CF46',
  limestone = '#CDCBBF',
  fadeAwayGray = '#F5F5F5',
  silver = '#BEBEBE',
  charcoal = '#666666',
  jetBlack = '#000000',
  slate = '#384D5B',
  red = '#FF5823',
  primary = '#2892D7',
  secondary = '#384D5B',
}

// TODO: Delete this after all colors have been switched over to using new Turtle theme
export enum ColorNames {
  starkWhite = 'starkWhite',
  skyBlue = 'skyBlue',
  superiorBlue = 'superiorBlue',
  midnightBlue = 'midnightBlue',
  lichenGreen = 'lichenGreen',
  pineGreen = 'pineGreen',
  astralYellow = 'astralYellow',
  limestone = 'limestone',
  fadeAwayGray = 'fadeAwayGray',
  silver = 'silver',
  charcoal = 'charcoal',
  jetBlack = 'jetBlack',
  slate = 'slate',
  red = 'red',
  primary = 'primary',
  secondary = 'secondary',
  alert = 'alert',
  warning = 'warning',
  success = 'success',
}

export enum BackgroundColorClasses {
  main = 'bg-bgc-main',
  elevated = 'bg-bgc-elevated',
  accent = 'bg-bgc-accent',
  backdrop = 'bg-bgc-backdrop',
  menu = 'bg-bgc-menu',
}

export enum TextColorClasses {
  primary = 'text-tc-primary',
  secondary = 'text-tc-secondary',
  disabled = 'text-tc-disabled',
  success = 'text-tc-success',
  warning = 'text-tc-warning',
  critical = 'text-tc-critical',
  onaccent = 'text-tc-onaccent',
}
