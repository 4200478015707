import { IconProps } from '@coldpbc/interfaces';
import React from 'react';

export const ColdScoreIcon = (props: IconProps) => {
  return (
    <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2577 5.5237L20.6485 0.636353H17.5382L15.1378 4.09606C15.1377 4.09595 15.1375 4.09585 15.1373 4.09574L13.5275 6.41619C13.5277 6.41619 13.5279 6.4163 13.5281 6.41641L5.37118 18.1736C4.00008 16.8599 3.14629 15.027 3.14629 13.0007C3.14629 9.01516 6.44516 5.77262 10.5001 5.77262C10.62 5.77262 10.739 5.77594 10.8575 5.78152L12.637 3.21655C11.9475 3.07123 11.2331 2.99305 10.5001 2.99305C4.88585 2.99305 0.318237 7.48242 0.318237 13.0007C0.318237 15.9805 1.65212 18.6586 3.76189 20.4932L0.38294 25.3636H3.49272L12.1755 12.8484H12.176L15.6474 7.8449C17.0079 9.15736 17.8539 10.9834 17.8539 13.0007C17.8539 16.9862 14.5551 20.2288 10.5001 20.2288C10.3897 20.2288 10.2803 20.2252 10.1712 20.2205L8.38908 22.7892C9.07048 22.9311 9.77632 23.0069 10.5001 23.0069C16.1144 23.0069 20.6819 18.5181 20.6819 13.0007C20.6819 10.0296 19.3564 7.35823 17.2577 5.5237Z"
        fill={props.color || 'white'}
      />
    </svg>
  );
};
