import { createTheme } from '@mui/material';

export const muiTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: ['Inter'].join(','),
  },
});
