import { IconProps } from '@coldpbc/interfaces';

export const ColdDangerIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C8.41015 6 5.5 8.91015 5.5 12.5C5.5 16.0899 8.41015 19 12 19C15.5899 19 18.5 16.0899 18.5 12.5C18.5 8.91015 15.5899 6 12 6ZM12 4.5C7.58172 4.5 4 8.08172 4 12.5C4 16.9183 7.58172 20.5 12 20.5C16.4183 20.5 20 16.9183 20 12.5C20 8.08172 16.4183 4.5 12 4.5ZM12 13.5C11.4477 13.5 11 13.0523 11 12.5V9.5C11 8.94772 11.4477 8.5 12 8.5C12.5523 8.5 13 8.94772 13 9.5L13 12.5C13 13.0523 12.5523 13.5 12 13.5ZM12 14.5C11.4477 14.5 11 14.9477 11 15.5C11 16.0523 11.4477 16.5 12 16.5C12.5523 16.5 13 16.0523 13 15.5C13 14.9477 12.5523 14.5 12 14.5Z"
        fill={props.color || 'white'}
      />
    </svg>
  );
};
