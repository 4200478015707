import * as React from 'react';
import { HexColors } from '@coldpbc/themes';

export const ColdWordmark = (
  props: { color: string; stroke?: string } & React.SVGProps<SVGSVGElement>,
) => {
  const color: string = props.color || HexColors.white;
  const strokeColor: string = props.stroke || color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="92"
      height="29"
      viewBox="0 0 92 29"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.2686 0L46.2639 5.73183C48.7427 7.88329 50.3083 11.0162 50.3083 14.5007C50.3083 20.9718 44.9135 26.2362 38.2825 26.2362C37.4273 26.2362 36.5936 26.1473 35.7888 25.981L37.894 22.9682C38.0228 22.9739 38.1521 22.9779 38.2825 22.9779C43.0717 22.9779 46.9679 19.1752 46.9679 14.5007C46.9679 12.1349 45.9687 9.99356 44.3617 8.45406L40.2617 14.3222H40.261L30.0059 29H26.333L30.3237 23.2881C27.8319 21.1362 26.2563 17.9954 26.2563 14.5007C26.2563 8.02897 31.6511 2.7638 38.2825 2.7638C39.148 2.7638 39.9917 2.85561 40.8061 3.02586L38.7043 6.03402C38.5647 6.02751 38.424 6.02389 38.2825 6.02389C33.4929 6.02389 29.5966 9.82656 29.5966 14.5007C29.5966 16.8774 30.6052 19.027 32.2244 20.5676L41.8587 6.77864H41.858L43.7594 4.05714C43.7594 4.05714 43.7596 4.05725 43.7597 4.05736C43.7599 4.05743 43.76 4.0575 43.7602 4.0575L46.595 0H50.2686ZM3.33996 14.5007C3.33996 19.1753 7.23658 22.9779 12.0258 22.9779C16.058 22.9779 19.4568 20.2824 20.4305 16.6406H23.8479C22.8134 22.0927 17.9079 26.2362 12.0258 26.2362C5.39475 26.2362 0 20.9718 0 14.5007C0 8.02897 5.39475 2.7638 12.0258 2.7638C17.9072 2.7638 22.8127 6.90697 23.8475 12.3594H20.4301C19.4557 8.71865 16.0572 6.02389 12.0258 6.02389C7.23658 6.02389 3.33996 9.82656 3.33996 14.5007ZM53.6286 3.0829H57.2956V22.6579H69.1762V25.9177H53.6286V3.0829ZM80.2799 22.6579H76.0503V6.34369H80.2799C85.3291 6.34369 88.3435 9.42847 88.3435 14.595C88.3435 19.5684 85.2539 22.6579 80.2799 22.6579ZM80.2799 3.08287H72.3603V25.9176H80.2799C87.3068 25.9176 91.8471 21.4737 91.8471 14.596C91.8471 7.49461 87.415 3.08287 80.2799 3.08287Z"
        fill={strokeColor}
      />
    </svg>
  );
};
