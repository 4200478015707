export enum ActionItemVariants {
  narrow = 'narrow',
  wide = 'wide',
  SubcategoryActionDetailsCard = 'SubcategoryActionDetailsCard',
}

export enum StepDetailsVariants {
  SubcategoryActionDetailsCard = 'SubcategoryActionDetailsCard',
  ActionDetailProgress = 'ActionDetailProgress',
}

export enum ActionDetailCardVariants {
  SubcategoryActionDetailsCard = 'SubcategoryActionDetailsCard',
  ActionDetailProgress = 'ActionDetailProgress',
}
