import { IconProps } from '@coldpbc/interfaces';

export const ColdCalendarDaysIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        d="M8.3811 4H7.5813C6.46119 4 5.90072 4 5.4729 4.21799C5.09658 4.40973 4.79084 4.71547 4.59909 5.0918C4.3811 5.51962 4.3811 6.08009 4.3811 7.2002V8M8.3811 4H16.3811M8.3811 4V2M16.3811 4H17.1813C18.3014 4 18.8607 4 19.2885 4.21799C19.6648 4.40973 19.9716 4.71547 20.1633 5.0918C20.3811 5.5192 20.3811 6.07899 20.3811 7.19691V8M16.3811 4V2M4.3811 8V16.8002C4.3811 17.9203 4.3811 18.4801 4.59909 18.9079C4.79084 19.2842 5.09658 19.5905 5.4729 19.7822C5.90031 20 6.4601 20 7.57801 20H17.1842C18.3021 20 18.8611 20 19.2885 19.7822C19.6648 19.5905 19.9716 19.2842 20.1633 18.9079C20.3811 18.4805 20.3811 17.9215 20.3811 16.8036V8M4.3811 8H20.3811M16.3811 16H16.3831L16.3831 16.002L16.3811 16.002V16ZM12.3811 16H12.3831L12.3831 16.002L12.3811 16.002V16ZM8.3811 16H8.3831L8.38306 16.002L8.3811 16.002V16ZM16.3831 12V12.002L16.3811 12.002V12H16.3831ZM12.3811 12H12.3831L12.3831 12.002L12.3811 12.002V12ZM8.3811 12H8.3831L8.38306 12.002L8.3811 12.002V12Z"
        stroke={props.color || '#E0E1EA'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
