// get all the survey component types from the survey input component code
export enum SurveyComponentType {
  yes_no = 'yes_no',
  text = 'text',
  currency = 'currency',
  number = 'number',
  percent_slider = 'percent_slider',
  multi_select = 'multi_select',
  select = 'select',
  textarea = 'textarea',
}
